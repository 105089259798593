import { graphql } from 'gatsby';
import React from 'react';
import SEO from '../components/SEO';
import Nav from '../components/Nav';
import styled from 'styled-components';
import Header from '../components/Header';

const BioStyle = styled.div`
      padding: 2rem 0 2rem 0;

  h2 {
    padding: 1rem;
    //background-color: var(--color_0);
    border-radius: 3px;
    font-style: italic;
  }

  p{
    margin: 0 auto;
    max-width: 700px;
    padding: 2rem 0 0 0;

    @media (max-width: 800px) {
      padding: 2rem 0 0 0;
      width: 90%;
      margin: 0 auto;
    }

  }
`;

export default function BioPage({ data }) {
  return (
    <>
      <SEO title="Bio" />
      <Header />
      <Nav categorias={data.categorias.nodes}/>
      <BioStyle>
        <div className="center">
          <h2>Te cuento un poco sobre mi...</h2>
        </div>
        <div className="hand-drawn-divider-2"></div>
        <div>
          <p>
            Por aquellos años, en los que decidí estudiar psicología me generaba intriga saber que pensaban y sentían los seres humanos. Así fue que comencé a estudiar en la Universidad de Buenos Aires esta licenciatura y me enamoré de la que sería mi profesión.
          </p>
        </div>
        <div className="divider div-transparent div-arrow-down" hidden></div>
        <div className="hand-drawn-divider-1"></div>
        <div>
          <p>
            A lo largo de estos años tuve la oportunidad de recorrer diferentes caminos, transitando por distintas áreas que me permitieron conocer a diferentes personas, a quienes tuve el placer de ayudar a descubir pensamientos y sentimientos.
            <br/>
            <br/>
            Niños en escuelas, internos de nosocomios, pacientes en mi consultorio. Gente sufriendo, personas con necesidad de hablar y aqui una profesional con deseo de escuchar.
          </p>
        </div>
        <div className="hand-drawn-divider-1"></div>
        <div>
          <p>
            Luego de estudiar la licenciatura en la UBA, realicé diferentes cursos de posgrado realizando mi práctica clínica en el Centro de Salud Mental N3, en el Centro Asistencial Centro Dos, y hace muchos años pertenezco al equipo de trabajo de Sendas Analíticas, siempre actualizando mi formación en la área clínica y de psicodiagnóstico.
            <br/>
            <br/>
            Actualmente cumplo además la función de Perito Psicóloga del Poder Judicial de la Nación.
          </p>
        </div>
        <div className="hand-drawn-divider-2"></div>
      </BioStyle>
    </>
  );
}

export const query = graphql`
  query
  {
    categorias: allSanityCategory {
      nodes {
        title
      }
    }
  }
`;
